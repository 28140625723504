import { useState } from "react"
import styled from "styled-components"

import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { SettingDropdown } from "src/components/Settings/Setting/SettingDropdown"
import { SettingNumber } from "src/components/Settings/Setting/SettingNumber"
import { SettingSlider } from "src/components/Settings/Setting/SettingSlider"
import { SettingStatic } from "src/components/Settings/Setting/SettingStatic"
import { SettingText } from "src/components/Settings/Setting/SettingText"
import { SettingTimeRange } from "src/components/Settings/Setting/SettingTimeRange"
import { SettingToggle } from "src/components/Settings/Setting/SettingToggle"
import { useTranslate } from "src/i18n/useTranslate"
import { TMSelectOption } from "src/ui/MSelect/MSelectV2"
import { sleep } from "src/utils/genericUtil"

export function SettingsDemo() {
  const { t, langKeys } = useTranslate()
  async function simulateDelayedSave(action: () => void) {
    await sleep(saveLag)
    action()
    return {
      isSuccess: true,
    }
  }

  const [text1, setText1] = useState("Text 1 initial")
  async function onSaveText1(newVal: string) {
    await simulateDelayedSave(() => setText1(newVal))
    return {
      isSuccess: true,
    }
  }

  const [saveLag, setNumber1] = useState(1100)
  async function onSetSaveLag(
    newVal: number
  ): TSettingContainerOnSaveReturnType {
    await simulateDelayedSave(() => setNumber1(newVal))
    return {
      isSuccess: true,
    }
  }

  const [slider1, setSlider1] = useState(7)
  async function onSaveSlider1(
    newVal: number
  ): TSettingContainerOnSaveReturnType {
    await simulateDelayedSave(() => setSlider1(newVal))
    return {
      isSuccess: true,
    }
  }

  const [sliderRange1, setSliderRange1] = useState("22:00 - 06:00")
  async function onSaveSliderRange1(
    newVal: string
  ): TSettingContainerOnSaveReturnType {
    await simulateDelayedSave(() => setSliderRange1(newVal))
    return {
      isSuccess: true,
    }
  }
  const [toggle1, setToggle1] = useState(true)
  async function onSaveToggle1(
    newVal: boolean
  ): TSettingContainerOnSaveReturnType {
    await simulateDelayedSave(() => setToggle1(newVal))

    return {
      isSuccess: true,
    }
  }

  const [toggle2, setToggle2] = useState("off")
  async function onSaveToggle2(
    newVal: string
  ): TSettingContainerOnSaveReturnType {
    await simulateDelayedSave(() => setToggle2(newVal))

    return {
      isSuccess: true,
    }
  }

  const [errorText, setErrorText] = useState("error")
  async function onSaveError(newVal: string) {
    if (newVal === "error") {
      return {
        isSuccess: false,
      }
    }

    await simulateDelayedSave(() => setErrorText(newVal))

    return {
      isSuccess: true,
    }
  }

  const [errorToggle, setErrorToggle] = useState(false)
  async function onToggleError(newVal: boolean) {
    if (newVal === false) {
      return {
        isSuccess: false,
      }
    }

    await simulateDelayedSave(() => setErrorToggle(newVal))

    return {
      isSuccess: true,
    }
  }

  const dropdownOptions: TMSelectOption[] = [
    { label: "Foo", value: "foo" },
    { label: t(langKeys.on), value: "on" },
    { label: t(langKeys.off), value: "off" },
    { label: "bar", value: "bar" },
    { label: "Disabled", value: "disabled", disabled: true },
  ]
  const [dropdown1, setDropdown1] = useState(dropdownOptions[1].value)
  async function onSaveDropdown1(
    newVal: string
  ): TSettingContainerOnSaveReturnType {
    await simulateDelayedSave(() => setDropdown1(newVal))

    return {
      isSuccess: true,
    }
  }

  return (
    <SettingsDemoContainer>
      <SettingNumber
        title={`Simulated setting lag`}
        description={`Adjust sleep time for simulating network requests`}
        onSave={onSetSaveLag}
        value={saveLag}
        min={500}
        minRecommended={650}
        max={1000}
        maxRecommended={900}
        displayValueSuffix={`ms`}
        textFieldProps={{
          label: "Lag (milliseconds)",
          inputProps: { step: 100 },
        }}
        errorHelperText={(type) => {
          switch (type) {
            case "below":
              return "Minimum value is 500 ms"
            case "belowSoft":
              return "Below recommended threshold"
            case "above":
              return "Maximum value is 1000 ms"

            default:
              return null
          }
        }}
      />

      <SettingStatic title={`Static setting`} value={`${saveLag} ms`} />

      <SettingText
        title={`Text input title`}
        description={`Text input description`}
        onSave={onSaveText1}
        value={text1}
      />

      <SettingToggle
        title={`Boolean Toggle input title`}
        description={`Toggle input description: ${toggle1 ? "true" : "false"}`}
        onSave={onSaveToggle1}
        value={toggle1}
      />

      <SettingToggle
        title={`Boolean Toggle with children`}
        onSave={onSaveToggle2}
        value={toggle2}
        toggleProps={{ trueVal: "on", falseVal: "off" }}
      >
        <ChildBox>
          <SettingTimeRange
            title={`Time range setting`}
            value={sliderRange1}
            onSave={onSaveSliderRange1}
          />
          <SettingText
            title={`Text input title`}
            description={`Text input description`}
            onSave={onSaveText1}
            value={text1}
          />
        </ChildBox>
      </SettingToggle>

      <SettingDropdown
        title={`Dropdown title`}
        description={`Dropdown description`}
        onSave={onSaveDropdown1}
        value={dropdown1}
        options={dropdownOptions}
      ></SettingDropdown>

      <SettingTimeRange
        title={`Time range setting`}
        value={sliderRange1}
        onSave={onSaveSliderRange1}
      />

      <SettingSlider
        title={`Slider setting`}
        value={slider1}
        onSave={onSaveSlider1}
        min={0}
        max={10}
        unit={"minutes"}
      />

      <SettingText
        title="Error on save"
        description={`Try writing "error" and save`}
        value={errorText}
        onSave={onSaveError}
      />

      <SettingToggle
        title={`Boolean Toggle with error`}
        onSave={onToggleError}
        value={errorToggle}
      />
    </SettingsDemoContainer>
  )
}

const SettingsDemoContainer = styled.div`
  display: grid;
  gap: 1rem;
  & > * {
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
  }
`

const ChildBox = styled.div`
  margin-left: 1rem;
  display: grid;
  gap: 1rem;
`
