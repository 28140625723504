import { useState } from "react"

import { Divider } from "src/ui/Divider/Divider"
import HomeIcon from "src/ui/icons/home.svg"
import { MSelectV2, TMSelectOptions } from "src/ui/MSelect/MSelectV2"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const options: TMSelectOptions = [
  {
    label: "Not a value",
    value: "",
    disabled: true,
  },
  { label: "Option 1", value: "value1" },
  { label: "Option 2", value: "value2" },
  { label: "Option 3", value: "value3" },
  { label: "Option 4", value: "value4" },
  { label: "Option 5", value: "value5" },
  { label: "Option 6", value: "value6" },
  { label: "Option 7", value: "value7" },
  { label: "Option 8", value: "value8" },
  { label: "Option 9", value: "value9" },
  { label: "Option 10", value: "value10" },
]

const groupedOptions: TMSelectOptions = [
  { label: "Standalone Option 1", value: "standalone1" },
  { label: "Standalone Option 2", value: "standalone2" },
  {
    title: "Group 1",
    options: [
      { label: "Grouped Option 1.1", value: "group1.1" },
      { label: "Grouped Option 1.2", value: "group1.2" },
    ],
  },
  { label: "Standalone Option 3", value: "standalone3" },
  {
    title: "Group 2",
    options: [
      { label: "Grouped Option 2.1", value: "group2.1" },
      { label: "Grouped Option 2.2", value: "group2.2" },
      { label: "Grouped Option 2.3", value: "group2.3" },
    ],
  },
  { label: "Standalone Option 4", value: "standalone4" },
  {
    title: "Group 3",
    options: [
      { label: "Grouped Option 3.1", value: "group3.1" },
      { label: "Grouped Option 3.2", value: "group3.2" },
    ],
  },
  { label: "Standalone Option 5", value: "standalone5" },
]

export function MSelectV2Demo() {
  const [showStartAdornment, setShowStartAdornment] = useState(false)

  const [defaultSelectedValue, setDefaultSelectedValue] = useState("")
  const [groupedSelectedValue, setGroupedSelectedValue] =
    useState("standalone1")

  const startAdornment = <HomeIcon width={18} />

  return (
    <div>
      <label>
        <input
          type="checkbox"
          checked={showStartAdornment}
          onChange={(e) => setShowStartAdornment(e.target.checked)}
        />
        Show start adornment
      </label>

      <MText variant="heading3" marginBottom={spacing.M}>
        Default
      </MText>
      <MSelectV2
        label="Default"
        value={defaultSelectedValue}
        options={options}
        onChange={(value) => setDefaultSelectedValue(value)}
        helperText="This is some helper text"
        startAdornment={showStartAdornment && startAdornment}
      />

      <Divider $margin={spacing.M} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Grouped options
      </MText>
      <MSelectV2
        label="Grouped"
        value={groupedSelectedValue}
        options={groupedOptions}
        onChange={(value) => setGroupedSelectedValue(value)}
        startAdornment={showStartAdornment && startAdornment}
      />

      <Divider $margin={spacing.M} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Without label
      </MText>
      <MSelectV2
        value={defaultSelectedValue}
        options={options}
        onChange={(value) => setDefaultSelectedValue(value)}
        startAdornment={showStartAdornment && startAdornment}
        ariaLabel="Without label"
      />

      <Divider $margin={spacing.M} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Required
      </MText>
      <form onSubmit={(e) => e.preventDefault()}>
        <MSelectV2
          label="Required"
          value={defaultSelectedValue}
          options={options}
          onChange={(value) => setDefaultSelectedValue(value)}
          required
          startAdornment={showStartAdornment && startAdornment}
        />
        <button>Submit</button>
      </form>

      <Divider $margin={spacing.M} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Error (Select "Option 4")
      </MText>
      <MSelectV2
        label="Error"
        value={defaultSelectedValue}
        options={options}
        onChange={(value) => setDefaultSelectedValue(value)}
        error={defaultSelectedValue === "value4" ? "This is an error" : ""}
        startAdornment={showStartAdornment && startAdornment}
      />

      <Divider $margin={spacing.M} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Disabled
      </MText>
      <MSelectV2
        label="Disabled"
        value={defaultSelectedValue}
        options={options}
        onChange={(value) => setDefaultSelectedValue(value)}
        disabled
        startAdornment={showStartAdornment && startAdornment}
      />
    </div>
  )
}
