import { useState } from "react"
import styled from "styled-components"

import { Select, Switch, TextField } from "@material-ui/core"

import { UsersFilter } from "src/components/Sandbox/FilterDemo/UsersFilter"
import { useSearchParams } from "src/router/useSearchParams"
import { Filter } from "src/ui/Filter/Filter"
import { FilterChip } from "src/ui/Filter/FilterChip"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function FilterDemo() {
  const {
    searchParams: filterSearchParams,
    setSearchParams: setFilterSearchParams,
  } = useSearchParams({
    keys: [
      {
        key: "filter_demo_string",
        type: "string",
      },
      {
        key: "filter_demo_number",
        type: "number",
      },
      {
        key: "filter_demo_boolean",
        type: "boolean",
      },
    ],
  })

  const [filter, setFilter] = useState<{
    chipFilter: string
    textFilter: string
    numberFilter: number
    selectFilter: {
      label: string
      value: string
    }
    multiFilter: {
      label: string
      value: string
    }[]
  }>({
    chipFilter: "",
    textFilter: "",
    numberFilter: 0,
    selectFilter: {
      label: "",
      value: "",
    },
    multiFilter: [],
  })

  return (
    <Wrapper>
      <div>
        <MText variant="heading3">Filter chip</MText>
        <FilterChip
          label="Chip filter"
          value={filter.chipFilter}
          active={!!filter.chipFilter}
          onRemove={() => {
            setFilter((prev) => ({ ...prev, chipFilter: "" }))
          }}
          removeLabel="Clear filter"
        >
          <StyledSearch>
            <TextField
              placeholder="Chip filter"
              fullWidth
              size="small"
              value={filter.chipFilter}
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, chipFilter: e.target.value }))
              }
            />
          </StyledSearch>
        </FilterChip>
      </div>
      <div>
        <MText variant="heading3">Filter</MText>
        <Filter
          filters={[
            {
              id: "text_filter",
              label: "Text filter",
              value: filter.textFilter,
              active: !!filter.textFilter,
              onRemove: () => {
                setFilter((prev) => ({
                  ...prev,
                  textFilter: "",
                }))
              },
              render: (
                <StyledSearch>
                  <TextField
                    placeholder="Text filter"
                    fullWidth
                    size="small"
                    value={filter.textFilter}
                    onChange={(e) =>
                      setFilter((prev) => ({
                        ...prev,
                        textFilter: e.target.value,
                      }))
                    }
                  />
                </StyledSearch>
              ),
              alwaysVisible: true,
            },
            {
              id: "number_filter",
              label: "Number fillter",
              value: filter.numberFilter.toString(),
              active: filter.numberFilter !== 0,
              onRemove: () => {
                setFilter((prev) => ({
                  ...prev,
                  numberFilter: 0,
                }))
              },
              render: (
                <StyledSearch>
                  <TextField
                    placeholder="Number filter"
                    fullWidth
                    size="small"
                    value={filter.numberFilter}
                    onChange={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        numberFilter: parseInt(e.target.value),
                      }))
                    }}
                    type="number"
                  />
                </StyledSearch>
              ),
            },
            {
              id: "select_filter",
              label: "Select fillter",
              value: filter.selectFilter.label,
              active: !!filter.selectFilter.value,
              onRemove: () => {
                setFilter((prev) => ({
                  ...prev,
                  selectFilter: {
                    value: "",
                    label: "",
                  },
                }))
              },
              onAdd: () => {
                setFilter((prev) => ({
                  ...prev,
                  selectFilter: {
                    value: "all",
                    label: "All",
                  },
                }))
              },
              render: (
                <StyledSearch>
                  <Select
                    native
                    fullWidth
                    onChange={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        selectFilter: {
                          label: (e.target as HTMLSelectElement).options[
                            (e.target as HTMLSelectElement).selectedIndex
                          ].text,
                          value: e.target.value as string,
                        },
                      }))
                    }}
                  >
                    <option value="all">All</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Select>
                </StyledSearch>
              ),
            },
            {
              id: "multi_filter",
              label: "Multi fillter",
              value:
                filter.multiFilter.length > 1
                  ? `${filter.multiFilter[0]?.label} and ${filter.multiFilter.length - 1} more`
                  : filter.multiFilter[0]?.label,
              active: filter.multiFilter.length > 0,
              onRemove: () => {
                setFilter((prev) => ({
                  ...prev,
                  multiFilter: [],
                }))
              },
              render: (
                <UsersFilter
                  selectedValues={filter.multiFilter}
                  onChange={(selected) =>
                    setFilter((prev) => ({ ...prev, multiFilter: selected }))
                  }
                />
              ),
            },
          ]}
        />
      </div>
      <div>
        <MText variant="heading3">Filter with useSearchParams</MText>
        <Filter
          filters={[
            {
              id: "searchParams_text_filter",
              label: "Text filter",
              value: filterSearchParams.filter_demo_string ?? "",
              active: !!filterSearchParams.filter_demo_string,
              onRemove: () => {
                setFilterSearchParams("filter_demo_string", null)
              },
              render: (
                <StyledSearch>
                  <TextField
                    placeholder="Text filter"
                    fullWidth
                    size="small"
                    value={filterSearchParams.filter_demo_string ?? ""}
                    onChange={(e) =>
                      setFilterSearchParams(
                        "filter_demo_string",
                        e.target.value
                      )
                    }
                  />
                </StyledSearch>
              ),
              alwaysVisible: true,
            },
            {
              id: "searchParams_number_filter",
              label: "Number fillter",
              value: String(filterSearchParams.filter_demo_number),
              active: !!filterSearchParams.filter_demo_number,
              onRemove: () => {
                setFilterSearchParams("filter_demo_number", null)
              },
              render: (
                <StyledSearch>
                  <TextField
                    placeholder="Number filter"
                    fullWidth
                    size="small"
                    value={filterSearchParams.filter_demo_number ?? ""}
                    onChange={(e) => {
                      setFilterSearchParams(
                        "filter_demo_number",
                        parseInt(e.target.value)
                      )
                    }}
                    type="number"
                  />
                </StyledSearch>
              ),
            },
            {
              id: "searchParams_switch_filter",
              label: "Switch fillter",
              value: String(filterSearchParams.filter_demo_boolean),
              active: filterSearchParams.filter_demo_boolean !== null,
              onRemove: () => {
                setFilterSearchParams("filter_demo_boolean", null)
              },
              onAdd: () => {
                setFilterSearchParams("filter_demo_boolean", true)
              },
              render: (
                <StyledSearch>
                  <Switch
                    checked={!!filterSearchParams.filter_demo_boolean}
                    onChange={(_, checked) => {
                      setFilterSearchParams("filter_demo_boolean", checked)
                    }}
                  />
                </StyledSearch>
              ),
            },
          ]}
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  gap: ${spacing.L};
`

const StyledSearch = styled.div`
  padding: 0 ${spacing.S} ${spacing.S} ${spacing.S};
`
